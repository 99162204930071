.design-box {
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.design-blurb {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  text-justify: inter-word;
  padding-bottom: 20px;
}

.footer {
  padding-top: 40px;
}