.navbar {
  background-color: #242424;
  height: 80px;
  display: flex;
  font-size: 1.1rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  justify-self: start;
  margin-left: 10px;
  transform: rotate(-7deg);
  align-items: center;
  height: 80px;
}

.nav-item {
  height: 80px;
  list-style-type: none;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.1rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.navbar-socials {
  position: absolute;
  right: 0px;
  padding: 20px;
}

.nav-social {
  color: #fff;
  margin-left: 15px;
}