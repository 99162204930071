.about-box {
  height: '100vh';
  min-height: '100vh';
  margin-top: 17%;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.about-me {
  text-align: justify;
  text-justify: inter-word;
}

.about-links {
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.about-link {
  color: #fff;
}