* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Courier', sans-serif;
  color: #fff;
}

body, root {
  width: 100%;
  min-height: 100%;
}

.App {
  text-align: center;
}
